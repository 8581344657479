import React from 'react';

import MainLayout from 'src/layout/MainLayout';
import { Button } from 'src/components';

import content from 'src/content/404';

const NotFoundPage = () => (
  <MainLayout title={content.title}>
    <section className="no-children-margin bg-light-blue text-center px-4">
      <h1 className="text-4xl">
        <span className="inline-block text-5xl text-turquoise mb-2">404</span>
        <br />
        {content.headline}
      </h1>
      <p className="mt-12 mb-8">{content.paragraph}</p>
      <Button href="/" variant="turquoise">
        {content.buttonText}
      </Button>
    </section>
  </MainLayout>
);

export default NotFoundPage;
